import {
  TextField,
  CardContent,
  Card,
  Button,
  CardActions,
  Typography,
  Grid,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import ListIcon from "@material-ui/icons/List";

export default function AddPages() {
  return (
    <div>
      <Card xs={12} variant="outlined">
        <CardContent>
          <Grid justify="space-between" container spacing={3}>
            <Grid item>
              <Typography variant="h6">Add Pages</Typography>
            </Grid>
            <Grid item>
              <IconButton
                style={{ "margin-top": "-10px", height: "50px" }}
                color="secondary"
              >
                <ListIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ "margin-top": "25px" }}>
            <Grid container sm={12} xs={12} md={12} spacing={3}>
              <Grid xs={12} md={4} item>
                <TextField
                  label="Controller Name"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <TextField label="Action Name" fullWidth variant="outlined" />
              </Grid>
              <Grid xs={12} md={4} item>
                <TextField label="Page Name" fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid container sm={12} xs={12} md={12} spacing={3}>
              <Grid xs={12} md={4} item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="User Type"
                  >
                    <MenuItem value={0}>
                      <em>SELECT</em>
                    </MenuItem>
                    <MenuItem value={"Admin"}>Company Admin</MenuItem>
                    <MenuItem value={"User"}>Company Employee</MenuItem>
                    <MenuItem value={"Both"}>Both</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={4} item>
                <TextField
                  label="Sequence No."
                  type="number"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid
            justify="space-between" // Add it here :)
            container
            spacing={20}
          >
            <Grid item></Grid>

            <Grid style={{ "margin-right": "16px" }} item>
              <div>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  color="secondary"
                >
                  SAVE
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
}
