import {
  TextField,
  CardContent,
  Card,
  Button,
  CardActions,
  Typography,
  Grid,
  IconButton,
  Divider
} from "@material-ui/core";
import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import ListIcon from '@material-ui/icons/List';

export default function AddUserRole() {
  return (
    <div>
      <Card xs={12} variant="outlined">
        <CardContent>
          <Grid justify="space-between" container spacing={3}>
            <Grid item>
              <Typography variant="h6">Add Role</Typography>
            </Grid>
            <Grid item>
              <IconButton style={{"margin-top":"-10px","height":"50px"}} color="secondary" >
                <ListIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ "margin-top": "25px" }}>
            <Grid container sm={12} xs={12} md={12} spacing={1}>
              <Grid xs={12} md={12} item>
                <TextField label="Role Name" fullWidth variant="outlined" />
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid
            justify="space-between" // Add it here :)
            container
            spacing={20}
          >
            <Grid item></Grid>

            <Grid style={{ "margin-right": "16px" }} item>
              <div>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  color="secondary"
                >
                  SAVE
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
}
