import React from "react";
import Dashboard from "./MyComponents/Dashboard";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import AddUserRole from "./MyComponents/Role/AddUserRole";
import AddModule from "./MyComponents/Module/AddModule";
import AddPages from "./MyComponents/Pages/AddPages";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Dashboard>
            <Typography variant="h6">Dashboard</Typography>
          </Dashboard>
        </Route>
        <Route path="/master/addrole" exact>
          <Dashboard>
            <AddUserRole></AddUserRole>
          </Dashboard>
        </Route>
        <Route path="/master/addmodule" exact>
          <Dashboard>
            <AddModule/>
          </Dashboard>
        </Route>
        <Route path="/master/addpages" exact>
          <Dashboard>
            <AddPages></AddPages>
          </Dashboard>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
