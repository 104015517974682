import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import ViewModuleOutlinedIcon from "@material-ui/icons/ViewModuleOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import PagesOutlinedIcon from "@material-ui/icons/PagesOutlined";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
          >
            <Grid item>
              <Link
                style={{ "text-decoration": "none", color: "white" }}
                to="/"
              >
                <Typography
                  style={{ "padding-top": "8px" }}
                  variant="h6"
                  noWrap
                >
                  Supar Admin
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <IconButton color="inherit" aria-label="open drawer" edge="end">
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
          >
            <Grid item>
              <Typography
                color="primary"
                style={{
                  "padding-top": "8px",
                  "padding-left": "15px",
                  "font-weight": "bold",
                }}
                variant="h4"
                noWrap
              >
                AMS
              </Typography>
            </Grid>

            <Grid item>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Typography
          color="secondary"
          style={{
            "padding-top": "20px",
            "padding-left": "20px",
            "font-weight": "bold",
            "font-size": "18px",
          }}
          variant="b"
          noWrap
        >
          MASTER
        </Typography>
        <Divider />
        <List>
          <Link
            style={{ "text-decoration": "none", color: "black" }}
            to="/master/addrole"
          >
            <ListItem button key={"Manage Role"}>
              <ListItemIcon>
                <SupervisedUserCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Manage Role"} />
            </ListItem>
          </Link>
          <Link
            style={{ "text-decoration": "none", color: "black" }}
            to="/master/addmodule"
          >
            <ListItem href="/test" component="a" button key={"Manage Module"}>
              <ListItemIcon>
                <ViewModuleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Manage Module"} />
            </ListItem>
          </Link>
          <Link
            style={{ "text-decoration": "none", color: "black" }}
            to="/master/addpages"
          >
            <ListItem href="/test" component="a" button key={"Manage Pages"}>
              <ListItemIcon>
                <PostAddOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Manage Pages"} />
            </ListItem>
          </Link>
          <Link
            style={{ "text-decoration": "none", color: "black" }}
            to="/master/assignpagetomodule"
          >
            <ListItem
              href="/test"
              component="a"
              button
              key={"Assign Pages To Module"}
            >
              <ListItemIcon>
                <PagesOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Assign Pages To Module"} />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
